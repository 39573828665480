import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("../views/IndexPage.vue"),
  },
  {
    path: "/detail",
    name: "DetailPage",
    component: () => import("../views/DetailPage.vue"),
  },
  {
    path: "/flash",
    name: "Flash",
    component: () => import("../views/NewsFlash.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});
export default router;
